@import "../../../assets/styles/base";

.app__main {
    background-color: #F4F4F4;
    
}

.app__content {
    min-height: calc(100vh - 70px);
    min-width: 600px;
    padding: 0px 32px 50px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media (max-width:  768px) {
    .app__content {
        min-width: 100%;
    }
  }