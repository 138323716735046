@import '../../../assets/styles/helpers/variables';

.toolbar__main {
  .MuiAppBar-colorPrimary {
    background-color: $white;
  }
}

.menu_items__container {
  a {
    text-decoration: none;
  }
}

.toolbar__main__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;

  &.MuiToolbar-regular {
    min-height: 53px;
    padding-left: 15px;
  }
}

.toolbar__main__content__icon {
  font-size: 36px;

  &.MuiIconButton-colorInherit {
    color: $main;
  }
}
.drawer__main {
  .MuiDrawer-paper {
    height: 93%;
    width: 240px;
  }
}

.header_drawer {
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 53px;
}

.header_drawer__profile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.header_drawer__profile-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.header_drawer__profile-content--img {
  border-radius: 50%;
  height: 30px;
  object-fit: cover;
  width: 30px;
}
.header_drawer__profile-content--text {
  color: $secondary;
  font-style: normal;
  font-size: 16px;
  font-weight: $weight-bold;
  line-height: 20px;
  margin-left: 6px;
}

.header_drawer__close {
  color: $main;

  &:hover {
    cursor: pointer;
  }
}

.list-main {
  .list-main__item {
    border-bottom: 1px solid rgba(216, 216, 216, 0.8);
    cursor: pointer;
    height: 51px;
    padding-left: 20px;
    padding-right: 20px;

    a {
      text-decoration: none;
    }
  }

  .list-main__item--icon {
    color: $violet;
    min-width: 30px;
  }
  .list-main__item--text {
    span {
      color: $medium-gray;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: $weight-bold;
      line-height: 20px;
    }
  }
}

.footer_drawer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  background: $white;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 53px;
  color: $medium-gray;
  cursor: pointer;

  .MuiSvgIcon-root {
    font-size: 30px;
    margin-left: 18px;
    margin-right: 8px;
  }
}

.footer_drawer__text {
  font-weight: $weight-bold;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: $md) {
  .toolbar__main__content {
    &.MuiToolbar-regular {
      padding-left: 0px;
    }
  }
}