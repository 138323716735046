@import "../../../assets/styles/base";

.general_modal_main {
  background: #fff;
  min-width: 320px;
  max-width: 320px;
  min-height: 86px !important;
  width: auto;

  > div {
    padding: 0;
    display: flex;
    flex-direction: column;

    .general_model_top_content {
      padding: 25px 28px 22px 18px;
      display: flex;
      position: relative;
      width: 100%;

      > div{
        margin: 1px 6px 0 14px;
        max-width: 224px;
        padding: 0;
      }
    }

    .general_modal_text{
      margin: 0;
    }

    .general_modal_close {
      cursor: pointer;
      padding: 5px;
      background-color: #fff;
      color: #929292;
      font-size: 25px;
      position: absolute;
      top: 15px;
      right: 23px;
    }

    .general_modal_button {
      border-radius: 0;
      background: #00AA80;
      margin-bottom: 0;
    }

    > .MuiContainer-root {
      padding: 0;
    }
  }
}

svg.MuiSvgIcon-colorError{
  fill: #EB5757 !important;
}

